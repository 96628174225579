import { Section } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import { ErrorMessage } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import PageSection from "@whitespace/gatsby-theme-smorgasbord/src/components/PageSection";
import SectionHeader from "@whitespace/gatsby-theme-smorgasbord/src/components/SectionHeader";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { camelCase } from "lodash-es";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default withComponentDefaults(Article, "article");

Article.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

function Article({ className, styles = defaultStyles, ...restProps }) {
  const { t } = useTranslation();
  const {
    contentNode: { title },
  } = usePageContext();
  const [error, setError] = useState();

  useEffect(() => {
    let url = new URL(window.location.href);
    let { error, ...otherParams } = qs.parse(url.search.substring(1));
    if (error) {
      setError(error);
      let search = qs.stringify(otherParams);
      url.search = search ? `?${search}` : "";
      window.history.replaceState(null, "", url.href);
    }
  }, []);

  return (
    <article className={clsx(className)} {...restProps}>
      <PageSection>
        {!!error && (
          <ErrorMessage
            error={
              t([
                `bankid.error.${camelCase(error || "unknown")}`,
                `bankid.error.unknown`,
              ]) +
              (process.env.GATSBY_DEBUG_LOGIN && error ? ` (${error})` : "")
            }
            severity="error"
          />
        )}
        <SectionHeader title={title}>{/* */}</SectionHeader>
        <Section>
          <ArticleBody />
        </Section>
      </PageSection>
    </article>
  );
}
