import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import CookieConsentProvider from "@whitespace/gatsby-plugin-cookie-consent/src/CookieConsentProvider";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import React from "react";

function CookieConsentWrapper({ children }) {
  const { active, position, strings } = useCookieConsentSettings();
  return (
    <>
      {children}
      <CookieConsent
        position={position}
        cookieConsentSettings={strings}
        active={active}
      />
    </>
  );
}

export const wrapRootElement = ({ element }) => {
  return <CookieConsentProvider>{element}</CookieConsentProvider>;
};

export const wrapPageElement = ({ element }) => {
  return <CookieConsentWrapper>{element}</CookieConsentWrapper>;
};
