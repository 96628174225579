import Logo from "@whitespace/gatsby-theme-smorgasbord/src/components/Logo";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header.module.css";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.header)}>
        <HeaderLogo align="center" components={{ Logo }} linkTo="/" />
      </div>
    </header>
  );
}
